import styled, { css } from 'styled-components'
import { applyStyles, SIZES } from 'context/layoutContext'
import { SvgIcon } from 'ui-kit/svg'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'
import { Button } from 'ui-kit'

export const SPage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

export const STopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 16px;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    margin: 0 12px;
  }
`

export const SGamesList = styled.div`
  display: grid;
  grid-gap: 16px;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 8px;
    `)}

    ${applyStyles([SIZES.W512], theme.contentSize, css`
      grid-template-columns: 1fr 1fr 1fr;
    `)}

    ${applyStyles([SIZES.W688], theme.contentSize, css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `)}

    ${applyStyles([SIZES.W1040], theme.contentSize, css`
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    `)}
  `};
`

export const SLoadMore = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
`

export const SLoadButton = styled(Button)`
  padding: 12px 58px;
`

export const SHeader = styled.div`
  margin: 16px 0 0;
  padding: 6px 0;
  display: flex;
  gap: 12px;
  align-items: center;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      padding: 6px 12px;
      grid-gap: 8px;
    `)}
  `};
`

export const STitle = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  flex: 1;
  color: ${COLORS.dark80};
  ${FONTS.h2};
`

export const SBackArrow = styled(SvgIcon)`
  width: 24px;
  height: 14px;
  color: ${COLORS.dark200};
  cursor: pointer;

  &:hover {
    color: white;
  }
`

