import { useEffect } from 'react'

import { NextPage, GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { DEFAULT_LANGUAGE } from 'types/language'
import countries from 'i18n-iso-countries'
import {
  fetchGames, fetchLiveGames, fetchTopGames, fetchGameSessionHistoryLastRows, fetchProviders, fetchTournamentHistory
} from 'components/for_pages/HomePage/requests'
import { IHomePage } from 'components/for_pages/HomePage/types'
import { SPage, SSectionContent, STopSection } from 'components/for_pages/HomePage/styles'
import { QueryClient } from '@tanstack/react-query'
import {
  NavigationsBannerSection,
  CrashGames, HotGames, LiveGames, Providers, RichyGames, Statistics, TopGamesInCountry, Tournaments, SliderSection
} from 'components/for_pages/HomePage'
import { getIsMobileForServer } from 'utils/mobile'
import { IGameHistory } from 'data/interfaces/IGameHistory'
import { IGame } from 'data/interfaces/IGame'
import { IGameProvider } from 'data/interfaces/IGameProvider'
import { ITournamentHistory } from 'data/interfaces/ITournamentHistory'
import { Layout } from 'components/layout/Layout/Layout'
import { useRouter } from 'next/router'

import { useAppContext } from '../context/AppContext'
import { ModalType, PaymentSwitchFilterKey, ProfileModalType } from '../types/enums'
import { PaymentHistoryModalArguments } from '../types/interfaces'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export const IndexPage: NextPage<IHomePage> = ({
  providers,
  topGames,
  topGamesInCountry,
  livesGames,
  crashGames,
  // richyGames,
  tournaments,
  statistics
}) => {
  const { t, i18n } = useTranslation()
  const appContext = useAppContext()
  const router = useRouter()

  useEffect(() => {
    if (!appContext.initialLoaded) return

    if (router.query.withdrawal) {
      appContext.showModalProfile(ProfileModalType.paymentHistory, {
        filter: PaymentSwitchFilterKey.Applications,
      } as PaymentHistoryModalArguments)

      router.replace('/', '/', { shallow: true })
    }

    if (router.query.registration) {
      if (!appContext.auth) {
        appContext.showModal(ModalType.registration)
      }

      router.replace('/', '/', { shallow: true })
    }

    if (router.query.fortune) {
      appContext.showModal(ModalType.fortune)
      router.replace('/', '/', { shallow: true })
    }

    if (router.query.astropay) {
      appContext.showModal(ModalType.astroPay)
      router.replace('/', '/', { shallow: true })
    }

    if (router.query.payment_status) {
      router.query.payment_status === ModalType.depositSuccess && appContext.showModal(ModalType.depositSuccess)
      router.query.payment_status === ModalType.depositFail && appContext.showModal(ModalType.depositFail)
      router.query.payment_status === ModalType.withdrawSuccess && appContext.showModal(ModalType.withdrawSuccess)
      router.query.payment_status === ModalType.withdrawFail && appContext.showModal(ModalType.withdrawFail)
      router.replace('/', '/', { shallow: true })
    }
  }, [router.query, appContext.initialLoaded, appContext.auth])

  return (
    <Layout
      withoutPadding
      isNewPage
    >
      <NextSeo
        title={t('new_seo_title')}
        description={t('new_seo_description')}
        canonical={`https://richy.casino${i18n.language === DEFAULT_LANGUAGE ? '' : '/' + i18n.language}`}
      />

      <SPage>
        <STopSection>
          <SliderSection />

          <NavigationsBannerSection />
        </STopSection>

        <SSectionContent>
          {/*<RichyGames games={richyGames} />*/}

          <HotGames games={topGames} />

          <TopGamesInCountry games={topGamesInCountry} />

          <LiveGames games={livesGames} />

          <Providers providers={providers} />

          <CrashGames games={crashGames} />

          <Tournaments tournaments={tournaments} />

          <Statistics statistics={statistics} />
        </SSectionContent>
      </SPage>
    </Layout>
  )
}

const queryClient = new QueryClient()

export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<IHomePage>> {
  const isMobile = getIsMobileForServer(context?.req)
  const language = context.locale

  await Promise.all([
    queryClient.prefetchQuery(['fetchProviders'], () => fetchProviders({ name: '' })),
    // queryClient.prefetchQuery(['fetchRichyGames', isMobile], () => fetchGames({ isMobile, providerId: 1 })),
    queryClient.prefetchQuery(['fetchTopGamesInCountry', isMobile], () => fetchGames({ isMobile, categoryId: 21 })),
    queryClient.prefetchQuery(['fetchTopGames', isMobile], () => fetchGames({ isMobile, categoryId: 25 })),
    queryClient.prefetchQuery(['fetchLivesGames', isMobile], () => fetchLiveGames({ isMobile })),
    queryClient.prefetchQuery(['fetchCrashGames', isMobile], () => fetchGames({ isMobile, categoryId: 10 })),
    queryClient.prefetchQuery(['fetchGameSessionHistoryLastRows'], () => fetchGameSessionHistoryLastRows(7)),
    queryClient.prefetchQuery(['fetchTournamentHistory'], () => fetchTournamentHistory(1, language))
  ])

  const providers: { data: IGameProvider[] } | undefined = await queryClient.getQueryData(['fetchProviders'])
  // const richyGames: { data: IGame[] } | undefined  = await queryClient.getQueryData(['fetchRichyGames', isMobile])
  const topGames: { data: IGame[] } | undefined = await queryClient.getQueryData(['fetchTopGames', isMobile])
  const topGamesInCountry: { data: IGame[] } | undefined = await queryClient.getQueryData(['fetchTopGamesInCountry', isMobile])
  const livesGames: { data: IGame[] } | undefined = await queryClient.getQueryData(['fetchLivesGames', isMobile])
  const crashGames: { data: IGame[] } | undefined = await queryClient.getQueryData(['fetchCrashGames', isMobile])
  const statistics: { data: IGameHistory[] } | undefined = await queryClient.getQueryData(['fetchGameSessionHistoryLastRows'])
  const tournaments: { data: ITournamentHistory[] } | undefined = await queryClient.getQueryData(['fetchTournamentHistory'])

  return {
    props: {
      // richyGames: richyGames?.data || [],
      providers: providers?.data || [],
      topGames: topGames?.data || [],
      topGamesInCountry: topGamesInCountry?.data || [],
      livesGames: livesGames?.data || [],
      crashGames: crashGames?.data || [],
      statistics: statistics?.data || [],
      tournaments: tournaments?.data || []
    },
  }
}

export default IndexPage
